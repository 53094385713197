"use client";

/**
 * A simple wrapper around fetch that throws an error if the response is not 200-299.
 * @param url The URL to fetch. Must be one of the ApiEndpoints enum.
 * @param body The request body to send. Optional.
 * @returns The JSON response.
 */
export async function fetcher<T = any>(
  url: string,
  body?: RequestInit
): Promise<T> {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      ...body,
    });

    const data = await response.json();

    if (!response.ok) {
      if(Array.isArray(data)){
        throw new Error(data[0].Message);
      }
      const errorMessage = data?.detail || `${response.status}: ${response.statusText}`;
      throw new Error(errorMessage);
    }

    return data;
  } catch (error) {
    console.error("Erro no fetcher:", error);
    throw error;
  }
}
