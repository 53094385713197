import { create } from "zustand";

type LatLng = { lat: number, lng: number }
type LocationStore = {
  place: google.maps.places.PlaceResult | null
  location: LatLng | null
  address: string
  setPlace: (place: google.maps.places.PlaceResult) => void
  setLocation: (latLnt: LatLng, address?: string) => void
}

export const useLocationStore = create<LocationStore>((set) => {

  return {
    location: null,
    address: "",
    place: null,
    setLocation: (latLnt: LatLng, address?: string) => {
      set({ location: latLnt, address: address || '' });

    },
    setPlace: (place: google.maps.places.PlaceResult) => {
      if (place.geometry?.location) {
        set({ location: place.geometry.location.toJSON() });
      }
      set({ place: place });
    }
  }
});
