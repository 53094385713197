"use client";

import { Property } from "@/@types/global";
import { useState, useEffect, useContext, createContext, ReactNode } from "react";
import { useInView } from "react-intersection-observer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Skeleton } from "@/components/ui/skeleton";
import { Routes } from "@/@types/api/routes.enum";
import { fetcher } from "@/lib/fetcher";
import { returnIdsLimitedParams } from "@/lib/utils";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import Link from "next/link";
import { CardDescription } from "@/components/ui/card";
import Image from "next/image";
import { Bath, X } from "lucide-react";

type ContextType = {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  setIds: (value: string[]) => void;
  clearData: () => void;
};

const ViewsPropertiesContext = createContext<ContextType>({
  isOpen: false,
  setOpen: () => {},
  setIds: () => {},
  clearData: () => {},
});

type Props = {
  children: ReactNode;
};

export const ViewsPropertiesProvider = ({ children }: Props) => {
  const [ids, setIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<Property[]>([]);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { ref, inView } = useInView();

  const handleOpen = (value: boolean) => {
    if (!value) setIds([]);
    setIsOpen(value);
  };


  const loadProperties = async (newPage: number) => {
    if (isLoading) return; // Prevenir múltiplas requisições simultâneas
    setIsLoading(true);
  
    try {
      const output = returnIdsLimitedParams(ids.map((e) => e?.toString()), newPage);
      const response = await fetcher(`${Routes.PropertiesPag}?${output}&size=12`);
      const newProperties = response?.data || [];
      setData((prev) => {
        return [...prev, ...newProperties];
      });
    
      if (newProperties.length < 12) {
        setHasMore(false);
      } else {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Erro ao carregar propriedades:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClear = ()=>{
    setData([]);
  }

  useEffect(() => {
    if (ids.length > 0 && isOpen) {
      setPage(0); 
      setHasMore(true);
      loadProperties(0); 
    }
  }, [ids, isOpen]);

  useEffect(() => {
    if (inView && hasMore) {
      loadProperties(page);
    }
  }, [inView, hasMore, page]);

  return (
    <ViewsPropertiesContext.Provider value={{ isOpen, setOpen: handleOpen, setIds, clearData: handleClear }}>
      {children}
      {window.location.pathname === '/' && isOpen && (
        <div className="fixed bottom-2 p-2 flex z-[500] w-full flex-col items-center justify-center">
          <div
            onClick={() =>{
              handleClear();
              setIsOpen(false);
            }}
            className="absolute right-2 -top-7 cursor-pointer bg-white rounded-full w-[30px] h-[30px] flex items-center justify-center z-[800]"
          >
            <X size={20} />
          </div>
          <Swiper
            className="w-full flex gap-2"
            spaceBetween={30}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            slidesPerView={1.5}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            breakpoints={{
              300: { slidesPerView: 1.5, spaceBetween: 10 },
              768: { slidesPerView: 3.5, spaceBetween: 20 },
              1024: { slidesPerView: 4.5, spaceBetween: 30 },
              1200: { slidesPerView: 5.5, spaceBetween: 30 },
            }}
          >
            {data.map((card, idx) => (
              <SwiperSlide key={`card-slider-${card.id}-${idx}`}>
                <CardView data={card} />
              </SwiperSlide>
            ))}
            {isLoading && (
              <SwiperSlide>
                <Skeleton className="h-[250px] w-full" />
              </SwiperSlide>
            )}
            <SwiperSlide>
              <div ref={ref} className="w-full h-[1px] bg-transparent" />
            </SwiperSlide>
          </Swiper>
        </div>
      )}
    </ViewsPropertiesContext.Provider>
  );
};

export const useViewsPropertiesContext = () => useContext(ViewsPropertiesContext);

const CardView = ({ data }: { data: Property }) => {
  const imgs = data.attachments.slice(0, 3).map((img, index) => (
      <CarouselItem className="w-full h-[150px] rounded-t-md relative ">
        <img
          src={img}
          alt={`${data.title} - Imagem ${index + 1}`}
          className="w-full h-full z-10 object-fill rounded-t-md"
        />
        <Skeleton className="w-[30rem] h-[150px] rounded-t-md absolute top-0 left-0" />
      </CarouselItem>
  ));

  return (
    <div className="w-full text-sm flex flex-col">
      <Carousel className="relative flex w-full">
        <CarouselContent>{imgs}</CarouselContent>
        <CarouselPrevious className="absolute left-0 bg-white" />
        <CarouselNext className="absolute right-0 bg-white" />
      </Carousel>
      <Link
        href={`/property/${data.slug}`}
        className="flex flex-col bg-white p-2 rounded-b-md gap-3"
      >
        <h1 className="capitalize text-base font-bold truncate">{data.title}</h1>
        <p className="truncate">{data?.location?.fullAddress}</p>
        <p className="truncate font-bold">{data?.price > 0 ?data?.price.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }): "Sob consulta"}</p>
        <div className="flex gap-3 justify-between w-full">
          <CardDescription className="flex gap-2">
            <Image src="/bad.svg" width={20} height={20} alt="Ícone de cama" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.bedrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Bath size={20} className="text-[#7B818F]" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.bathrooms}</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src="/subway.svg" width={20} height={20} alt="Ícone de área" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.areaTotal} m²</p>
          </CardDescription>
          <div className="h-[14px] w-[1px] bg-[#00000046]" />
          <CardDescription className="flex gap-2">
            <Image src="/garage.svg" width={20} height={20} alt="Ícone de garagem" />
            <p className="text-base text-[#7B818F] truncate">{data?.details?.garages}</p>
          </CardDescription>
        </div>
      </Link>
    </div>
  );
};
