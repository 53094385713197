"use client"
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import type { Property } from "../@types/global";
import { fetcher } from "./fetcher";
import { useLocationStore } from "@/store/location";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatPhoneNumber(phone: string): string {
	return phone.replace(
		/^(\d{2})(\d{4,5})(\d{4})$/,
		(match, ddd, prefix, suffix) => `(${ddd}) ${prefix}-${suffix}`
	);
}

export function transformPhoneNumber(phone: string): string {
  return phone.replace(/\D/g, "") // Remove tudo que não for número
  .replace(/^(\d{2})(\d)/, "($1) $2") // Adiciona (XX)
  .replace(/(\d{5})(\d)/, "$1-$2") // Adiciona o hífen
  .slice(0, 15) // Limita o tamanho
}

export function formatCNPJ(cnpj: string): string {
  const numericCNPJ = cnpj.replace(/\D/g, "");
  return numericCNPJ.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}

export const filterZoomMap = (
	data: Array<any>,
	currentZoom: number,
): Property[] => {
	if (currentZoom < 12) {
		return data.slice(0, Math.ceil(data.length * 0.4));
	} if (currentZoom < 15) {
		data.slice(0, Math.ceil(data.length * 0.7));
	}
	return data;
};

// export const transformLocation = async (
// 	address: google.maps.GeocoderResult,
// ) => {
//   console.log(address);
// 	const zipCode = address.address_components.find((component) =>
// 		component.types.includes("postal_code"),
// 	)?.long_name;
// 	if (!zipCode) return { city: null, neighborhood: null, state: null, zipCode };
// 	const data = await fetcher(`https://viacep.com.br/ws/${zipCode}/json/`);
// 	return {
// 		city: data.localidade,
// 		neighborhood: data.bairro,
// 		state: data.uf,
// 		zipCode,
// 	};
// };

export const transformLocation = (address: google.maps.GeocoderResult) => {
  console.log(address);

  const getComponent = (type: string) =>
    address.address_components.find((component) => component.types.includes(type))?.long_name || null;

  const zipCode = getComponent("postal_code");
  const city = getComponent("administrative_area_level_2");
  const state = getComponent("administrative_area_level_1");
  const neighborhood = getComponent("sublocality") || getComponent("political");
  const street = getComponent("route");
  return {
    zipCode,
    city,
    state,
    neighborhood,
    street,
  };
};
export const formatCurrency = (value = 0) => {
	return value.toLocaleString("pt-BR", {
		style: "currency",
		currency: "BRL",
	});
};


export const returnIdsLimitedParams = (idsParams: string[], page: number): string => {
  const ID_MAX = 12;
  if (!idsParams || idsParams.length === 0) return ""; // Verificação de ids vazios no início
  
  const startIdx = page * ID_MAX;
  const endIdx = Math.min(startIdx + ID_MAX, idsParams.length);
  const ids = idsParams.slice(startIdx, endIdx);
  return ids.map(id => `ids=${id}`).join("&");
};

export const getLatLngByIPOrGeo = async () => {
  if ('geolocation' in navigator) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const latLng = { lat: latitude, lng: longitude };

          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
            
            if (!response.ok) throw new Error(`Nominatim API error: ${response.statusText}`);
            
            const data = await response.json();
            const address = data.address?.city || data.address?.town || data.address?.village || 'Desconhecido';
            const state = data.address?.state || 'Estado desconhecido';
            
            if (useLocationStore?.getState()?.setLocation) {
              useLocationStore.getState().setLocation(latLng, `${address} - ${state}`);
            }

            try {
              localStorage.setItem('ipbase', JSON.stringify({ ...latLng, address: `${address} - ${state}` }));
            } catch (error) {
              console.warn('Erro ao salvar no localStorage:', error);
            }

            resolve({ ...latLng, address: `${address} - ${state}` });
          } catch (error) {
            console.warn('Erro ao buscar dados de localização:', error);
            resolve(latLng);
          }
        },
        (error) => {
          console.warn('Erro de geolocalização:', error.message);
          if (typeof getLatLngByIP === 'function') {
            resolve(getLatLngByIP());
          } else {
            resolve({ 
              lat: -23.533773,
              lng: -46.625290, 
              address: 'Localização desconhecida'
            });
          }
        }
      );
    });
  } else {
    return typeof getLatLngByIP === 'function' ? getLatLngByIP() : { lat: 0, lng: 0, address: 'Localização desconhecida' };
  }
};


const getLatLngByIP = async () => {
  try {
    const response = await fetch(`https://api.ipbase.com/v1/json/`);
    const output = await response.json();
    const { data } = output;
    const { latitude, longitude } = data;
    let address = `${data.region_name}-${data.city}`;
    localStorage.setItem('ipbase', JSON.stringify({ lat: latitude, lng: longitude, address: address }));
    useLocationStore.getState().setLocation({ lat: parseFloat(latitude), lng: parseFloat(longitude)}, address);
    return { lat: parseFloat(latitude), lng: parseFloat(longitude), address: address };
  } catch (error) {
    return {
      lat: -23.533773,
      lng: -46.625290,
    };
  }
};