"use client";
import { GitCompareArrows } from "lucide-react";
import Link from "next/link";
import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";
import type { Property } from "../@types/global";

type CompareContextType = {
	property: Property[];
	add: (property: Property) => void;
	remove: (property: Property) => void;
};
const CompareContext = createContext<CompareContextType>({
	property: [],
	add: (property: Property) => {},
	remove: (property: Property) => {},
});
type Props = {
	children: ReactNode;
};
export const CompareProvider = ({ children }: Props) => {
	const [isMounted, setIsMounted] = useState(false);
	const [property, setProperty] = useState<Property[]>([]);
	useEffect(() => {
		const localStoragePropertys = localStorage.getItem("property_comparar");
		if (localStoragePropertys) {
			setProperty(JSON.parse(localStoragePropertys));
		}
	}, []);
	const add = (property: Property) => {
		setProperty((prev) => {
			const newArr = [...prev, property];
			localStorage.setItem("property_comparar", JSON.stringify(newArr));
			return newArr;
		});
	};
	const remove = (property: Property) => {
		setProperty((prev) => {
			const newProperty = prev.filter((prop) => prop.id !== property.id);
			localStorage.setItem("property_comparar", JSON.stringify(newProperty));
			return newProperty;
		});
	};
	useEffect(() => {
		setIsMounted(true);
	}, []);
	if (!isMounted) return null;
	return (
		<CompareContext.Provider
			value={{
				add,
				property,
				remove,
			}}
		>
			<>
				{property.length > 1 && (
					<Link
						href={"/compare"}
						className="fixed bottom-0 z-[500] left-0 cursor-pointer rounded-br-3xl rounded-tr-3xl bg-[#4279ca] p-4"
					>
						<GitCompareArrows size={20} color="white" />
					</Link>
				)}
				{children}
			</>
		</CompareContext.Provider>
	);
};
export const compareContext = () => {
	return useContext(CompareContext);
};
