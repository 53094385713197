"use client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProgressBar } from "next-nprogress-bar";
import { type ReactNode } from "react";
import { TooltipProvider } from "../ui/tooltip";
import { ClusterProvider } from "./clusters-provider";
import { SessionProvider } from 'next-auth/react'
import ModalProvider from "../../contexts/modal-provider";
import { Toaster } from 'sonner';
import { ViewsPropertiesProvider } from "@/contexts/views-properties";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Head from 'next/head';
import { PageMetadataProvider } from "@/contexts/metadata-context";
import { CompareProvider } from "@/contexts/compare-context";

type Props = {
	children: ReactNode;
};

export default function Provider({ children }: Props) {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 24 * (60 * 1000),
			},
		},
	});

	return (
		<SessionProvider>
				<QueryClientProvider client={queryClient}>
					<PageMetadataProvider>
						<TooltipProvider>
							<ClusterProvider>
								<ModalProvider>
									<CompareProvider>
										<ViewsPropertiesProvider>
											<Head>
												{/* <!-- Google tag (gtag.js) */}
												<script
													async
													src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3836996695965694"
													crossOrigin="anonymous"
												></script>
												<script
													dangerouslySetInnerHTML={{
														__html: `
															window.dataLayer = window.dataLayer || [];
															function gtag(){dataLayer.push(arguments);}
															gtag('js', new Date());
															gtag('config', 'G-5JFYEJG0EE');
														`,
													}}
												></script>
												{/* <!-- End Google Tag Manager --> */}
											</Head>
											{children}
											<AppProgressBar
												height="4px"
												color="#063B89"
												options={{ showSpinner: false }}
												shallowRouting
												/>
											<Toaster position="top-center" richColors />
										</ViewsPropertiesProvider>
									</CompareProvider>
								</ModalProvider>
							</ClusterProvider>
						</TooltipProvider>
					</PageMetadataProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			{/* </TransitionProvider> */}
		</SessionProvider>
	);
}
